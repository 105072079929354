<ng-container *ngIf="freeShippingStatus$ | async as status">
    <div *ngIf="status && status.qualifiesForFreeShipping && 0 < status.amountUntilFreeShipping" class="">
        <div class="md:text-lg lg:text-2xl -mx-4 relative overflow-hidden">
            <img
                src="/assets/images/watercolour-paints-table.webp"
                alt="Section background"
                class="absolute top-0 right-0 h-full object-cover"
            />
            <div class="px-8 py-12 z-10 relative bg-dusky text-secondary-900 w-1/2">
                <div>You're only</div>
                <div class="font-bold uppercase">{{ status.amountUntilFreeShipping | formatPrice }} away</div>
                <div>from</div>
                <div class="font-bold">free delivery</div>
            </div>
        </div>
        <div class="px-4 py-4 text-sm">
            Enjoy free delivery
            <span *ngIf="status.freeShippingRestriction"> on {{ status.freeShippingRestriction }}</span>
            to
            <a [routerLink]="status.routerLink" class="text-link">{{ status.region }}</a> when you spend
            {{ status.freeShippingThreshold | formatPrice }} or more.
        </div>
        <div class="flex items-center justify-center mt-4">
            <button (click)="resolveWith(true)" class="btn btn-neutral">Continue shopping</button>
        </div>
    </div>
</ng-container>
