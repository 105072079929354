import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { combineLatest, delay, interval, Observable, scan, Subject, Subscription, switchMap, tap } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { ActiveService } from "../../core/providers/active-order/active.service";
import { StorageService } from "../../core/providers/storage/storage.service";
import { DataService } from "../../core/providers/data/data.service";
import { GetFreeShippingStatusDocument } from "../../common/gql/graphql";
import { StateService } from "../../core/providers/state.service";

/**
 * Options for the `PopupTriggerService.registerPopupTrigger` method.
 */
export interface PopupTriggerOptions {
    /** The ID of the campaign that this trigger is associated with. */
    campaignId: string;
    /** Whether to log debug information to the console. */
    enableLogging?: boolean;
    /** The minimum time in seconds that the user must have spent on the site before the trigger is activated. */
    minTimeOnSiteSeconds?: number;
    /** The minimum time in seconds that must elapse between each time the trigger is activated. */
    minTimeBetweenTriggersSeconds?: number;
    /** The maximum number of times the user can see this campaign in the given time window specified by `timesSeenByUserWindowDays`, defaults to 30 days */
    timesSeenByUserLessThan?: number;
    /** The number of days to consider when counting the number of times the user has seen this campaign. */
    timesSeenByUserWindowDays?: number;
    /** Whether the customer is already subscribed to the mailing list */
    customerIsSubscribedToMailingList?: boolean;
    /** Whether the customer is signed in or out */
    loginStatus?: "signedIn" | "signedOut";
    /** The country code that the customer's default shipping location must match. */
    shippingCountryCodeEquals?: string;
    /** Whether the customer's current order qualifies for free shipping. */
    orderQualifiesForFreeShipping?: boolean;
    /** The minimum total quantity of items in the customer's current order. */
    minimumOrderTotalQuantity?: number;
    /** Whether the cart drawer is open or closed */
    cartDrawerIsOpen?: boolean;
    /** The minimum number of pages the user must have visited before the trigger is activated. */
    minPagesVisited?: number;
}

/**
 * This service allows you to register a callback that will be triggered when a set of criteria are met.
 * The criteria are defined in the `PopupTriggerOptions` object.
 */
@Injectable({
    providedIn: "root"
})
export class PopupTriggerService implements OnDestroy {
    private campaignTriggered$ = new Subject<string>();
    private subscriptions: Array<Subscription> = [];

    constructor(
        private activeService: ActiveService,
        private storageService: StorageService,
        private dataService: DataService,
        private stateService: StateService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    /**
     * Register a popup trigger with the given criteria. When all criteria are met, the callback will be executed.
     */
    registerPopupTrigger(options: PopupTriggerOptions, callback: () => void) {
        if (!isPlatformBrowser(this.platformId)) {
            // This should not be executed during SSR
            return;
        }
        const criteria: Array<Observable<{ criteriaId: string; triggered: boolean }>> = [];
        const {
            campaignId,
            enableLogging,
            minTimeOnSiteSeconds,
            loginStatus,
            timesSeenByUserLessThan,
            customerIsSubscribedToMailingList,
            shippingCountryCodeEquals,
            orderQualifiesForFreeShipping,
            cartDrawerIsOpen,
            minPagesVisited,
            minTimeBetweenTriggersSeconds,
            timesSeenByUserWindowDays,
            minimumOrderTotalQuantity,
        } = options;

        function log(message: string) {
            enableLogging && console.log({ campaignId, message });
        }

        function addCriteriaId(criteriaId: string) {
            return (val: boolean) => ({ criteriaId, triggered: val });
        }

        if (minTimeBetweenTriggersSeconds) {
            criteria.push(
                this.campaignTriggered$.pipe(
                    switchMap(() => interval(1000)),
                    map((seconds) => seconds >= minTimeBetweenTriggersSeconds),
                    distinctUntilChanged(),
                    tap((val) => log(`minTimeBetweenTriggersSeconds triggered: ${val}`)),
                    startWith(true),
                    map(addCriteriaId("minTimeBetweenTriggersSeconds"))
                )
            );
        }
        if (minTimeOnSiteSeconds && minTimeOnSiteSeconds > 0) {
            criteria.push(
                interval(1000).pipe(
                    map((seconds) => seconds >= minTimeOnSiteSeconds),
                    distinctUntilChanged(),
                    tap((val) => log(`timeOnSiteSeconds triggered: ${val}`)),
                    map(addCriteriaId("minTimeOnSiteSeconds"))
                )
            );
        }
        if (loginStatus) {
            criteria.push(
                this.activeService.activeCustomer$.pipe(
                    map((customer) => {
                        if (loginStatus === "signedIn") {
                            return !!customer;
                        } else {
                            return !customer;
                        }
                    }),
                    map(addCriteriaId("loginStatus")),
                )
            );
        }
        if (timesSeenByUserLessThan != null) {
            criteria.push(
                this.campaignTriggered$.pipe(
                    delay(1000),
                    filter((campaignId) => campaignId === options.campaignId),
                    startWith(null),
                    map(() => {
                        const campaignsSeen =
                            this.storageService.getFromLocalStorage("marketingCampaignsSeen");
                        const windowInDays = timesSeenByUserWindowDays || 30;
                        return campaignsSeen?.filter((c) => c.campaignId === options.campaignId)
                            .filter((c) => new Date(c.seenAt).getTime() > Date.now() - windowInDays * 24 * 60 * 60 * 1000)
                            .length || 0;
                    }),
                    tap((timesSeen) => log(`timesSeenByUserLessThan count: ${timesSeen}`)),
                    map((timesSeen) => timesSeen < timesSeenByUserLessThan),
                    map(addCriteriaId("timesSeenByUserLessThan")),
                )
            );
        }
        if (customerIsSubscribedToMailingList != null) {
            criteria.push(
                this.activeService.activeCustomer$.pipe(
                    map((customer) => {
                        if (customer) {
                            const customerIsSubscribed = customer.customFields?.newsletterOptIn === true;
                            return customerIsSubscribedToMailingList === customerIsSubscribed;
                        } else {
                            return customerIsSubscribedToMailingList === false;
                        }
                    }),
                    map(addCriteriaId("customerIsSubscribedToMailingList"))
                )
            );
        }
        if (shippingCountryCodeEquals != null) {
            criteria.push(
                this.activeService.defaultShippingLocation$.pipe(
                    tap((location) => log(`Shipping country code: ${location.countryCode}`)),
                    map((location) => location.countryCode === shippingCountryCodeEquals),
                    distinctUntilChanged(),
                    map(addCriteriaId("shippingCountryCodeEquals")),
                )
            );
        }
        if (orderQualifiesForFreeShipping != null) {
            criteria.push(
                combineLatest([this.stateService.select(state => state.cartDrawerOpen), this.activeService.activeOrder$, this.activeService.defaultShippingLocation$]).pipe(
                    // This one is only ever applicable for UK customers
                    filter(([_, __, shippingLocation]) => shippingLocation.countryCode === "GB"),
                    // Only trigger when the cart drawer is closed, because
                    // when the cart drawer is open the free shipping status
                    // is already displayed to the user.
                    filter(([cartDrawerOpen, order]) => cartDrawerOpen === false && !!order),
                    map(([_, order]) => order?.subTotalWithTax),
                    distinctUntilChanged(),
                    debounceTime(5_000),
                    switchMap(() =>
                        this.dataService
                            .query(GetFreeShippingStatusDocument)
                            .pipe(
                                map(({ checkFreeShippingStatus }) => checkFreeShippingStatus.amountUntilFreeShipping ?? 100000),
                                tap((amountUntilFreeShipping) => log(`orderQualifiesForFreeShipping, amountUntilFreeShipping: ${amountUntilFreeShipping}`)),
                                map((amountUntilFreeShipping) => orderQualifiesForFreeShipping === true ? amountUntilFreeShipping <= 0 : amountUntilFreeShipping > 0))
                    ),
                    map(addCriteriaId("orderQualifiesForFreeShipping")),
                )
            );
        }
        if (cartDrawerIsOpen != null) {
            criteria.push(
                this.stateService.select(state => state.cartDrawerOpen).pipe(
                    tap((isOpen) => log(`cartDrawerIsOpen: ${isOpen}`)),
                    map((isOpen) => isOpen === cartDrawerIsOpen),
                    distinctUntilChanged(),
                    map(addCriteriaId("cartDrawerIsOpen")),
                )
            );
        }
        if (minPagesVisited != null && minPagesVisited > 0) {
            criteria.push(
                this.router.events.pipe(
                    filter((event) => event instanceof NavigationEnd),
                    scan((acc) => acc + 1, 0),
                    tap((pagesVisitedCount) => log(`pagesVisited: ${pagesVisitedCount}`)),
                    map((pagesVisitedCount) => pagesVisitedCount >= minPagesVisited),
                    distinctUntilChanged(),
                    map(addCriteriaId("minPagesVisited")),
                )
            );
        }
        if (minimumOrderTotalQuantity != null) {
            criteria.push(
                this.activeService.activeOrder$.pipe(
                    map((order) => order?.lines.reduce((total, line) => total + line.quantity, 0) ?? 0),
                    distinctUntilChanged(),
                    tap((totalQuantity) => log(`totalQuantity: ${totalQuantity}`)),
                    map((totalQuantity) => totalQuantity >= minimumOrderTotalQuantity),
                    map(addCriteriaId("minimumOrderTotalQuantity")),
                )
            );
        }

        if (criteria.length === 0) {
            throw new Error("No criteria provided for popup trigger");
        }
        this.subscriptions.push(
            combineLatest(criteria)
                .pipe(
                    tap((results) => {
                        if (enableLogging) {
                            console.log(`Results for campaign ${campaignId}:`);
                            console.table(results);
                        }
                    }),
                    filter((results) => results.every((result) => result.triggered === true))
                )
                .subscribe(() => {
                    callback();
                    const campaignsSeen = this.storageService.getFromLocalStorage("marketingCampaignsSeen");
                    this.storageService.setInLocalStorage("marketingCampaignsSeen", [
                        ...(campaignsSeen || []),
                        { campaignId: options.campaignId, seenAt: new Date().toISOString() }
                    ]);
                    this.campaignTriggered$.next(options.campaignId);
                })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
